import { Input } from "antd";
import "./Description.css";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../Context/AppContext";
import { trackUserInteraction } from "../../../Utils/common-utils";
const { TextArea } = Input;

const AdditionalDescription = ({setCurrentSubStep,setCurrentMainStep}) => {
	const { state, updateState } = useContext(AppContext);

	useEffect(() => {
		trackUserInteraction({
			type:"page",
			page: 'Description/AdditionalDescription',
			buttonName: 'Additional Description',
			buttonValue: 'Additional Description',
			timestamp: new Date().toISOString(),
			userId: state?.userDetails?.id,
			sessionId: state?.sessionId,
			organization_name: state?.organizationDetails?.organization_name,
			step: 8
		});
		updateState({
			...state,
			isSubStepValid: true,
		});
		if(state?.customUrlData?.additionalInfo===false && !state?.back){
			setCurrentMainStep(2)
			setCurrentSubStep(0)
		}
		else if(state?.customUrlData?.additionalInfo===false && state?.back){
			if(state?.customUrlData?.dispatchFeeDetails && state?.customUrlData?.dispatchFee){
			setCurrentMainStep(1)
			setCurrentSubStep(0)
			}
			else{
			setCurrentMainStep(0)
			setCurrentSubStep(1)
			}
		}
	}, []);

	return (
		<div className="AdditionalDescription_otr">
			<div className="bread_crumbs">
				{
						!state?.customUrlData && <p className="Service_text">
						{state?.problem?.trade?.trade?.name} - {state?.problem?.servicetype}{" "}
						- {state?.problem?.jobItem?.category_name}
					</p>
				}
			
			</div>
			<div className="content_textarea">
				<h6 className="header_heading">
					Additional information
					<hr />
				</h6>
				<p className="desc">
					Please enter any additional details you'd like to share about your
					needs or equipment.
				</p>
				<TextArea
					className="text_area"
					placeholder="Issue description"
					value={state?.additionalInfo?.additionalInfo}
					onChange={(e) =>
						updateState({
							...state,
							additionalInfo: {
								...state.additionalInfo,
								additionalInfo: e.target.value,
							},
						})
					}
				/>
			</div>
		</div>
	);
};

export default AdditionalDescription;
