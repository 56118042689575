import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../Context/AppContext";
import AddressStep from "./AddressStep";
import RadioButtonGroup from "../../General/RadioButton";
import "./ExistingCustomer.css";

function ChangeAddressStep({ customerDetails }) {
  const { state, updateState } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [radioOptions, setRadioOptions] = useState(null);
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const newAddressSelection = (addressDetails) => {
    const { street, city, state:addressState, country, zip } = addressDetails?.address;
    const {id, customerId,active,modifiedOn} = addressDetails;

    const label = `${street} ${city}, ${addressState}, ${country}, ${zip}`;
    const value = `${street}, ${city}, ${addressState}, ${country}, ${zip}`;
    setRadioOptions((prev) => [...prev, { label, value }]);
    updateState({
      ...state,
      isSubStepValid: true,
      existingCustomerDetails: [
        ...state.existingCustomerDetails,
        {
          address_street: street,
          address_city: city,
          address_state: addressState,
          address_country: country,
          address_zip: zip,
          customer_id: customerId,
          customer_name: state?.clientInfo?.customer_name,
          customer_type: state?.existingCustomerDetails?.[state?.existingCustomerDetails?.length-1]?.customer_type,
          customer_is_active: active,
          email: state?.clientInfo?.email,
          is_member: state?.existingCustomerDetails?.[state?.existingCustomerDetails?.length-1]?.is_member || false,
          location_id: id,
          mobilephone: state?.clientInfo?.mobilephone,
          modifiedOn_date:modifiedOn
        }
      ]
    });
  };

  const handleAddressSelection = (selectedAddress) => {
    const selectedCustomerDetails = state?.existingCustomerDetails.find(
      (details) => {
        const {
          address_street,
          address_city,
          address_state,
          address_country,
          address_zip,
        } = details;
        const fullAddress = `${address_street}, ${address_city}, ${address_state}, ${address_country}, ${address_zip}`;
        return fullAddress === selectedAddress;
      }
    );
    if (state?.isExistingCustomer) {
      if(selectedCustomerDetails){
        updateState({
          ...state,
          miscellaneous: {
            ...state.miscellaneous,
            fullAddress: selectedAddress,
          },
          isSubStepValid: true,
          clientInfo: {
            ...state.clientInfo,
            isExistingCustomer: true,
            address: { 
              location_id: selectedCustomerDetails?.location_id,
              address_street: selectedCustomerDetails?.address_street,
              address_city: selectedCustomerDetails?.address_city,
              address_state: selectedCustomerDetails?.address_state,
              address_zip: selectedCustomerDetails?.address_zip,
              address_country: selectedCustomerDetails?.address_country,
            },
            customer_id: selectedCustomerDetails?.customer_id,
            customer_name: state?.miscellaneous?.newCustomerData?.name,
            email: state?.miscellaneous?.newCustomerData?.email,
            is_member: selectedCustomerDetails?.is_member,
          },
        });
      }
   
    } else if(selectedCustomerDetails) {
        updateState({
          ...state,
          miscellaneous: {
            ...state.miscellaneous,
            fullAddress: selectedAddress,
          },
          isSubStepValid: true,
          clientInfo: {
            ...state.clientInfo,
            isExistingCustomer: true,
            address: {
              location_id: selectedCustomerDetails?.location_id,
              address_street: selectedCustomerDetails?.address_street,
              address_city: selectedCustomerDetails?.address_city,
              address_state: selectedCustomerDetails?.address_state,
              address_zip: selectedCustomerDetails?.address_zip,
              address_country: selectedCustomerDetails?.address_country,
            },
            customer_id: selectedCustomerDetails?.customer_id,
            customer_name: selectedCustomerDetails?.customer_name,
            email: selectedCustomerDetails?.email,
            is_member: selectedCustomerDetails?.is_member,
          },
        });
    }
  };

  useEffect(() => {
    const radioOptionsSet = new Set();
		

    setRadioOptions(
      state?.existingCustomerDetails?.reduce((options, details, index) => {
        const {
          address_street,
          address_city,
          address_state,
          address_country,
          address_zip,
        } = details;
        const maskedAddress = address_street.replace(/^\d+(\s|$)/, "****$1");
        const label = `${maskedAddress}, ${address_city}, ${address_state}, ${address_zip}`;
        const value = `${address_street}, ${address_city}, ${address_state}, ${address_country}, ${address_zip}`;
        const key = `radio-option-${index}`;

        if (!radioOptionsSet.has(value)) {
          radioOptionsSet.add(value);
          options.push({ label, value, key });
        }

        return options;
      }, [])
    );

    updateState({
      ...state,
      isSubStepValid: false,
	  newCustomerPagewhatconverts:false
    });

    if (state.miscellaneous.fullAddress) {
      updateState({
        ...state,
        isSubStepValid: true,
      });
    }
  }, []);
  return (
    <div className="ChangeAddressStep_otr">
      <h6 className="header_heading">
        Address where our team is needed
        <hr />
      </h6>
      <RadioButtonGroup
        options={radioOptions || []}
        onChange={(e) => handleAddressSelection(e.target.value)}
        value={state.miscellaneous.fullAddress}
      />
      <div className="action_otr">
        <button className="address_button" onClick={showModal}>
          ADD NEW ADDRESS
        </button>
      </div>
      <div className={isModalOpen ? "modal_main modal_show" : "modal_main"}>
        <div className="modal_content" key={isModalOpen}>
          <button className="close_icon_otr" onClick={showModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
            </svg>
          </button>
          <AddressStep
            onNewAddress={newAddressSelection}
            showModal={showModal}
          />
        </div>
      </div>
    </div>
  );
}

export default ChangeAddressStep;
