import React, { useEffect, useContext, useState } from "react";
import "./ProblemList.css";
import { icons } from "../../../Utils/constants";
import { AppContext } from "../../../Context/AppContext";
import { trackUserInteraction } from "../../../Utils/common-utils";

const ProblemList = ({ setCurrentMainStep, setCurrentSubStep }) => {
  const { state, updateState } = useContext(AppContext);
  const [TradeIcon, setTradeIcon] = useState(null);
  const [problemList, setProblemList] = useState(null);

  useEffect(() => {
    trackUserInteraction({
			type: 'page',
			page: 'Problem/ProblemList/',
			button: 'Category Selection',
			buttonName: 'Category Selection',
			buttonValue: 'Category Selection',
			timestamp: new Date().toISOString(),
			userId: state?.userDetails?.id,
			organization_name: state?.organizationDetails?.organization_name,
			sessionId: state?.clientInfo?.followupleadid,
			step: 3
		});
    
    const selectedTrade = state?.problem?.trade?.trade?.name;
    const selectedServiceType = state?.problem?.servicetype;
    const navigationMapping = state?.organizationDetails?.navigation_mapping;
    const jobsMapping = state?.organizationDetails?.jobs_mapping;

    const matchingTrade = navigationMapping.find(
      (item) => item.trade.name === selectedTrade
    );

    if (matchingTrade) {
      let matchingServiceTypeArray = matchingTrade[selectedServiceType];
      const jobTrade = jobsMapping.find(
        (item) => item.trade.tradeName === selectedTrade
      );

      const jobBusinessUnits = jobTrade[selectedServiceType];

      if (matchingServiceTypeArray) {
        const activeJobItems = jobBusinessUnits
          .filter((item) => item.is_active)
          .map((item) => item?.jobItem?.jobItem);

        let filteredActiveServiceTypes = matchingServiceTypeArray.filter(
          ({ category_name, is_job, jobs }) => {
            let firstCase = is_job && activeJobItems.includes(category_name);

            let secondCase =
              !is_job &&
              jobs.length &&
              jobs.some((item) =>
                jobBusinessUnits.find(
                  (unit) => unit.is_active && unit?.jobItem?.jobItem === item
                )
              );

            return firstCase || secondCase;
          }
        )
          setProblemList(filteredActiveServiceTypes);
      }
    }
  }, []);

  const handleJobState = (selectedJob, defaultSelectedJob,category_name="") => {
    trackUserInteraction({
			type: 'button',
			page: 'Problem/ProblemList/',
			button: selectedJob?.category_name || 'Category Selection',
			buttonName: selectedJob?.category_name || 'Category Selection',
			buttonValue: `${state?.problem?.trade?.trade?.name} - ${state?.problem?.servicetype} - ${selectedJob?.category_name}`,
			userId: state?.userDetails?.id,
			sessionId: state?.clientInfo?.followupleadid,
			organization_name: state?.organizationDetails?.organization_name,
			step: 3
		});
    const isSameCategory = state?.problem?.jobItem?.category_name === selectedJob?.category_name;
    updateState({
      ...state,
      isSubStepValid: true,
      additionalInfo: {
        ...state.additionalInfo,
        jobItemToBook: null,
      },
      problem: {
        ...state.problem,
        jobItem: selectedJob,
        questions: null,
      },
      miscellaneous: {
        ...state?.miscellaneous,
        ...(isSameCategory ? {} : { additionalQuestions: [] }),
        ...(!defaultSelectedJob && { agreementCheckbox: false }),
      },
      ...(state?.problem?.jobItem?.category_name===category_name && {
          additionalInfo: { ...state.additionalInfo, additionalInfo: "" },
        }),
    });

    setCurrentMainStep(2);
    setCurrentSubStep(0);
  };

  useEffect(() => {
    const tradeName = state.problem.trade?.trade?.name?.toLowerCase();
    setTradeIcon(icons[tradeName]);

    updateState({
      ...state,
      isSubStepValid: false,
    });

    if (state.problem.jobItem) {
      updateState({
        ...state,
        isSubStepValid: true,
      });
    }
  }, []);
  return (
    <div className="ProblemList_otr">
      <div className="list_header_otr">
        <div className="bread_crumbs">
          <p className="Service_text">
            {state?.problem?.trade?.trade?.name} - {state?.problem?.servicetype}
          </p>
        </div>
        <div className="heading_icon_otr">
          <h6 className="header_heading">
            {state?.problem?.trade?.trade?.name}
            <hr />
          </h6>
          <div className="icon_otr">{TradeIcon}</div>
        </div>
      </div>
      <div className="problem_list_inr">
        {problemList?.map((job) => {
          const isSelectedJob =
            state.problem.jobItem?.category_name === job?.category_name;
          return (
            <button
              key={job.category_name}
              className={`arrow_button ${
                isSelectedJob // folder_name
                  ? "clicked"
                  : ""
              }`}
              onClick={() => handleJobState(job, isSelectedJob,job.category_name)}
            >
              <span className="button_text">{job?.category_name}</span>

              <svg
                className="button_arrow"
                width="6"
                height="11"
                viewBox="0 0 6 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5625 0.414062L0.09375 0.859375C0 0.976562 0 1.16406 0.09375 1.25781L4.33594 5.5L0.09375 9.76562C0 9.85938 0 10.0469 0.09375 10.1641L0.5625 10.6094C0.679688 10.7266 0.84375 10.7266 0.960938 10.6094L5.88281 5.71094C5.97656 5.59375 5.97656 5.42969 5.88281 5.3125L0.960938 0.414062C0.84375 0.296875 0.679688 0.296875 0.5625 0.414062Z"
                  fill="#1F2327"
                ></path>
              </svg>
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default ProblemList;
