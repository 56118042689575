import React, { useContext, useEffect, useState } from "react";
import { PrimaryInput } from "../../General/Input/index.tsx";
import StateSelect from "../NewCustomer/StateSelect.jsx";
import { Button } from "antd";
import { Checkbox } from "antd";
import { AppContext } from "../../../Context/AppContext.js";
import "../NewCustomer/NewCustomer.css";
import { createAddress } from "../../../apis/address";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const AddressStep = ({ showModal, onNewAddress }) => {
	const { state } = useContext(AppContext);
	const [ownResidence, setOwnResidence] = useState(false);

	const handleAutoFillAddress = (place) => {
		const addressComponents = place.address_components || [];
		const streetNumber = addressComponents.find((component) =>
			component.types.includes("street_number")
		)?.long_name;
		const route = addressComponents.find((component) =>
			component.types.includes("route")
		)?.long_name;

		let street =
			streetNumber && route
				? `${streetNumber} ${route}`
				: route || streetNumber || "";

		const postalCode = addressComponents.find((component) =>
			component.types.includes("postal_code")
		)?.long_name;

		const locality = addressComponents.find((component) =>
			component.types.includes("locality")
		)?.long_name;

		const stateAbbreviation = addressComponents.find((component) =>
			component.types.includes("administrative_area_level_1")
		)?.short_name;

		const countryAbbreviation = place?.address_components.find((component) =>
			component.types.includes("country")
		  )?.short_name;

		const newInputErrors = {};

		if (!street) {
			newInputErrors.street = "Street is required";
		}

		if (!locality) {
			newInputErrors.city = "City is required";
		}

		if (!stateAbbreviation) {
			newInputErrors.state = "State is required";
		}

		if (!postalCode) {
			newInputErrors.zip = "Zip code is required";
		}

		setInputErrors(newInputErrors);

		setFormData((prevFormData) => ({
			...prevFormData,
			address: {
				...prevFormData.address,
				street: street || "",
				city: locality || "",
				state: stateAbbreviation || null,
				zip: postalCode || "",
				country:countryAbbreviation || "US"
			},
		}));

		handleZipCodeChange(postalCode);
	};

	const [formData, setFormData] = useState({
		organizationId: state?.organizationDetails?.id,
		customerID: state?.existingCustomerDetails
			? state?.existingCustomerDetails[0]?.customer_id
			: "",
		customerName: state?.existingCustomerDetails
			? state?.existingCustomerDetails[0]?.customer_name
			: "",
		address: {
			street: "",
			city: "",
			state: null,
			zip: "",
		},
	});


	const [inputErrors, setInputErrors] = useState({
		street: "",
		city: "",
		state: "",
		zip: "",
	});


	const [loading, setLoading] = useState(false);

	const validateField = (fieldName, value, labelText) => {
		if (fieldName === "city" && value) {
			if (!/^[A-Za-z\s]+$/.test(value.trim())) {
				setInputErrors((prevErrors) => ({
				  ...prevErrors,
				  [fieldName]: `${labelText} must contain letters only`,
				}));
				return false;
			  }
		}
		if (!value || (value?.length && value?.trim() === "")) {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: `${labelText} is required`,
			}));
			return false;
		} else {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: "",
			}));
			return true;
		}
	};

	const handleBlurChange = (fieldName, value, labelText) => {
		if (!value) {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: `${labelText} is required`,
			}));
		}
	};

	const handleInputChange = (field, value, labelText) => {
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				address: {
					...prevFormData.address,
					[field]: value,
				},
			};
		});

		validateField(field, value, labelText);
	};

	const handleAddAddress = async () => {
		try {
			setLoading(true);
			let res = await createAddress(formData);
			if (onNewAddress) onNewAddress(res);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
			if (showModal) showModal(false);
		}
	};

	const handleZipCodeChange = (zipCode) => {
		const zip = zipCode
		setFormData((prevFormData) => ({
			...prevFormData,
			address: {
				...prevFormData?.address,
				zip: zip,
			},
		}));

		if (zip !== "") {
			const isValidZip = state?.organizationDetails?.zipCodes.includes(zip);
			if (!isValidZip) {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					zip: "Sorry, we do not serve this area. Please call if you feel this is an error.",
				}));
			} else {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					zip: "",
				}));
			}
		} else {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				zip: "Zip code is required",
			}));
		}
	};

	const isFormValid =
		Object.values(inputErrors || {}).every((value) => value === "") &&
		Object.values(formData.address || {}).every((value) => value !== "");

	return (
		<div className="steps_content_inr AddressStep_otr">
			<h6 className="customer_heading">
				Address where our team is needed
				<hr />
			</h6>
			<p className="customer_desc">Please search for your address below</p>
			<div className="address_input_otr">
				<div className="input_otr">
					<ReactGoogleAutocomplete
						apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
						onPlaceSelected={(place) => handleAutoFillAddress(place)}
						className="address-autofill"
						options={{
							componentRestrictions: { country: ["us","ca"] },
							fields: [
								"address_components",
								"formatted_address",
								"geometry",
								"name",
							],
							strictBounds: false,
							types: ["address"],
						}}
					/>
				</div>

				<div className="or_text_otr">
					<hr />
					<span className="or_text">OR</span>
					<hr />
				</div>
			</div>
			<p className="customer_desc">
				If your address is not found, please enter it below
			</p>
			<div className="address_inputs_otr">
				<PrimaryInput
					className={`theme_input ${inputErrors?.street ? "error-border" : ""}`}
					labelText="Street Address"
					placeholder="Street Address"
					value={formData?.address?.street}
					onBlur={(value) =>
						handleBlurChange("street", value, "Street Address")
					}
					onChange={(value) =>
						handleInputChange("street", value, "Street Address")
					}
					error={inputErrors?.street}
					setInputErrors={setInputErrors}
				/>
				<PrimaryInput
					className={`theme_input`}
					labelText="Apartment / Unit"
					placeholder="Apartment / Unit"
					value={formData?.address?.apartment}
					onChange={(value) =>
						handleInputChange("apartment", value, "Apartment")
					}
				/>

				<PrimaryInput
					className={`theme_input ${inputErrors?.city ? "error-border" : ""}`}
					labelText="City"
					placeholder="City"
					value={formData?.address?.city}
					onBlur={(value) => handleBlurChange("city", value, "City")}
					onChange={(value) => handleInputChange("city", value, "City")}
					error={inputErrors?.city}
				/>

				<StateSelect
					value={formData?.address?.state}
					onChange={(value) => handleInputChange("state", value, "State")}
					labelText="State"
				/>

				<PrimaryInput
					className={`theme_input ${inputErrors?.zip ? "error-border" : ""}`}
					labelText="Zip Code"
					placeholder="Zip Code"
					value={formData?.address.zip}
					onBlur={(value) => handleBlurChange("zip", value, "Zip code")}
					onChange={(value) => handleZipCodeChange(value)}
					error={inputErrors?.zip}
				/>

				<Checkbox
					checked={ownResidence}
					onChange={(e) => setOwnResidence(e.target.checked)}
					className="theme_checkbox"
					style={{ display: "flex", alignItems: "center" }}
				>
					<span>I own this residence</span>
				</Checkbox>
				<Button
					className={`address_btn ${!isFormValid ? "disabled" : ""}`}
					onClick={handleAddAddress}
					disabled={!isFormValid}
					loading={loading}
				>
					<span className="btn_text">Add Address</span>
				</Button>
			</div>
		</div>
	);
};

export default AddressStep;
