import { Button } from "antd";
import React, { useEffect, useContext } from "react";
import { AppContext } from "../../../Context/AppContext";
import { icons } from "../../../Utils/constants";
import { trackUserInteraction } from "../../../Utils/common-utils.js";
import "./ServiceType.css";
const ServiceType = ({ setCurrentSubStep }) => {
	const { state, updateState } = useContext(AppContext);

	useEffect(()=>{
		trackUserInteraction({
			type: 'page',
			page: 'Problem/ServiceType',
			button: 'Service Selection',
			buttonName: 'Service Selection',
			buttonValue: 'Service Selection',
			timestamp: new Date().toISOString(),
			userId: state?.userDetails?.id,
			organization_name: state?.organizationDetails?.organization_name,
			sessionId: state?.clientInfo?.followupleadid,
			step: 2
		});
	},[])


	const handleItemClick = (item, selectedService) => {
		trackUserInteraction({
			type: 'button',
			page: 'Problem/ServiceType',
			button: item || 'Service Selection',
			buttonName: item || 'Service Selection',
			buttonValue: `${state?.problem?.trade?.trade?.name} - ${item}`,
			timestamp: new Date().toISOString(),
			userId: state?.userDetails?.id,
			organization_name: state?.organizationDetails?.organization_name,
			sessionId: state?.clientInfo?.followupleadid,
			step: 2
		});	

		updateState({
			...state,
			isSubStepValid: true,
			problem: {
				...state.problem,
				jobItem: "",
				serviceObj: selectedService,
				servicetype: item,
			},
		});

		setCurrentSubStep(2);
	};

	useEffect(() => {
		updateState({
			...state,
			isSubStepValid: false,
		});

		if (state.problem.servicetype) {
			updateState({
				...state,
				isSubStepValid: true,
			});
		}
	}, []);

	return (
		<div className="ServiceType_otr">
			<h6 className="customer_heading">
				What type of service do you need?
				<hr />
			</h6>
			<div className="Service_cards_otr">
				{state?.problem?.trade &&
					Object.entries(state?.problem?.trade)?.map(([key, services]) => {
						if (!state?.problem?.trade?.offeredServiceTypes[key]) return null;
						if (key !== "_id" && services.length > 0) {
							const ServiceIcon = icons[key];
							let serviceUrl=state?.organizationDetails?.services_logo?.filter((Logo)=>Logo?.serviceName?.toLowerCase()=== key)[0]?.url;
							const serviceIcon = serviceUrl ? (
								<img style={{objectFit:"contain",width:"21.5px",height:"21.5px"}} src={serviceUrl} alt={key} className="icon_otr" />
							) : (
								ServiceIcon  && <div className="icon_otr"><ServiceIcon /> </div>
							)
							return (
								<Button
									className={`service_card `}
									onClick={() => handleItemClick(key, services)}
									key={key}
								>
										<div className="trade_icon_otr">
							{serviceIcon}
						</div>
									<span
										className={`card_text ${
											state.problem.servicetype === key ? "clicked" : ""
										}`}
									>
										{key}
									</span>
								</Button>
							);
						}
						return null;
					})}
			</div>
		</div>
	);
};

export default ServiceType;
