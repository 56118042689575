import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Drawer, Result } from "antd";
import Stepper from "./Components/General/Stepper/index.jsx";
import ClientInfo from "./Components/ClientInfo/index.jsx";
import Problem from "./Components/Problem/index.jsx";
import Description from "./Components/Description/index.jsx";
import AppointmentCalendar from "./Components/AppointmentCalendar/index.jsx";
import AppointmentReview from "./Components/AppointmentReview/index.jsx";
import NavigationalButton from "./Components/General/Navigation/index.jsx";
import { getOrganizationDetails, getOrganizationDetailsByCustomUrl } from "./apis/organizationDetails.js";
import { LoadingSpinner } from "./Components/General/Spinner/index.tsx";
import { AppContext } from "./Context/AppContext.js";
import AppointmentCreated from "./Components/AppointmentReview/AppointmentCreated.jsx";
import {OpenFeatureProvider, OpenFeature, InMemoryProvider } from '@openfeature/react-sdk';
import { flagConfig } from "./Utils/constants.js";
import "./App.css";

const initialStepValues = {
  clientInfo: {
    name: "clientInfo",
    totalSteps: 0,
  },
  problem: {
    name: "problem",
    totalSteps: 0,
  },
  description: {
    name: "description",
    totalSteps: 0,
  },
  calendar: {
    name: "calendar",
    totalSteps: 0,
  },
  review: {
    name: "review",
    totalSteps: 0,
  },
};


const mainStepComponents = {
  0: initialStepValues?.clientInfo,
  1: initialStepValues?.problem,
  2: initialStepValues?.description,
  3: initialStepValues?.calendar,
  4: initialStepValues?.review,
};

const paramsmainStepComponents = {
  0: initialStepValues?.clientInfo,
  1: initialStepValues?.description,
  2: initialStepValues?.calendar,
  3: initialStepValues?.review,
};

const App = () => {
  const htmlRef = useRef(document.querySelector("html"));
  const [open, setOpen] = useState(true);
  const [orgLoading, setOrgLoading] = useState(true);
  const [orgError, setOrgError] = useState("");
  const [currentMainStep, setCurrentMainStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(null);
  const [totalSteps, setTotalSteps] = useState(initialStepValues);
  const [iscustomUrl, setIsCustomUrl] = useState(false);

  const { state, updateState, resetState } = useContext(AppContext);
  const myRef = useRef({ isDataArrived: false });

  const getQueryParams = (query) => {
    return query
      .substring(1)
      .split("&")
      .reduce((acc, pair) => {
        const [key, value] = pair.split("=");
        acc[decodeURIComponent(key)] = decodeURIComponent(value);
        return acc;
      }, {});
  };

  const FormComponents = [
    {
      component: (
        <ClientInfo
          setCurrentSubStep={setCurrentSubStep}
          currentSubStep={currentSubStep}
          setTotalSteps={setTotalSteps}
        />
      ),
    },
    {
      component: (
        <Problem
          currentSubStep={currentSubStep}
          setTotalSteps={setTotalSteps}
          setCurrentMainStep={setCurrentMainStep}
          setCurrentSubStep={setCurrentSubStep}
        />
      ),
    },
    {
      component: (
        <Description
          setCurrentSubStep={setCurrentSubStep}
          currentSubStep={currentSubStep}
          setTotalSteps={setTotalSteps}
        />
      ),
    },
    {
      component: <AppointmentCalendar />,
    },
    {
      component: <AppointmentReview />,
    },
  ];

  const paramFormComponents= [
    {
      component: (
        <ClientInfo
          setCurrentSubStep={setCurrentSubStep}
          currentSubStep={currentSubStep}
          setTotalSteps={setTotalSteps}
        />
      ),
    },
    {
      component: (
        <Description
          iscustomUrl={true}
          setCurrentSubStep={setCurrentSubStep}
          setCurrentMainStep={setCurrentMainStep}
          currentSubStep={currentSubStep}
          setTotalSteps={setTotalSteps}
        />
      ),
    },
    {
      component: <AppointmentCalendar />,
    },
    {
      component: <AppointmentReview />,
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setCurrentMainStep(0);
    setCurrentSubStep(null);
    resetState();
    // for integration
    window.parent.postMessage("closeIframe", "*");
    window.location.reload(); // Reload the entire app
  };

  const fetchOrganization = async (websiteURL, webCookies) => {
    try {
      const res = await getOrganizationDetails(websiteURL);
      const primaryColorOpacity = Math.round(
        Math.min(Math.max(0.1 || 1, 0), 1) * 255
      );
      const primaryColor2Opacity = Math.round(
        Math.min(Math.max(0.3 || 1, 0), 1) * 255
      );

      const setPrimaryColorOpacity =
        res?.data?.primaryColor +
        primaryColorOpacity.toString(16).toUpperCase();
      const setPrimaryColor2Opacity =
        res?.data?.primaryColor +
        primaryColor2Opacity.toString(16).toUpperCase();

      htmlRef?.current?.style.setProperty(
        "--primary-color",
        res?.data?.primaryColor
      );
      htmlRef?.current?.style.setProperty(
        "--primary-color",
        res?.data?.primaryColor
      );
      htmlRef?.current?.style.setProperty(
        "--primary-light",
        setPrimaryColorOpacity
      );
      htmlRef?.current?.style.setProperty(
        "--primary-light2",
        setPrimaryColor2Opacity
      );
      return res?.data
    } catch (err) {
      setOrgError(
        err?.response
          ? err?.response?.data?.message
          : "Something went wrong. Please check your network connection or reload again."
      );
    } finally {
      setOrgLoading(false);
    }
  };

  const handleAsyncEvent = async (event = null) => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    let data = event?.data;
    let newDataSiteUrl = data?.siteUrl || paramsObject.siteUrl || undefined;
    let newDataCookies = data?.cookies || paramsObject.cookies || undefined;

    if (newDataSiteUrl !== undefined && newDataCookies !== undefined) {
      let parsedCookies = newDataCookies;
      for (let i = 0; i < parsedCookies.split(";").length; i++) {
        if (
          parsedCookies.split(";")[i].split("=")[0].trim() ===
          "wc_client_current"
        ) {
          parsedCookies = parsedCookies.split(";")[i].split("=")[1];
          break;
        }
      }

      newDataCookies = parsedCookies;
      // console.log("parse cookies", newDataSiteUrl);
      if (state.organizationDetails === null) {
       const orgData=await fetchOrganization(newDataSiteUrl, newDataCookies);
       OpenFeature.setContext({
        id: orgData?.id,
      });
       if(paramsObject?.c){
        setIsCustomUrl(true)
        const customUrlData=await customUrlDetails(paramsObject?.c);
        if(customUrlData){
          const dispatchFeeStatus=customUrlData?.dispatchFee;
          const dispatchFeeDetails=dispatchFeeStatus?{
            ...state.additionalInfo.jobItemToBook,
            member_dispatch_fee:customUrlData.dispatchFeeDetails.member_dispatch_fee,
            dispatch_fee: customUrlData.dispatchFeeDetails.dispatch_fee,
            dispatch_header: customUrlData.dispatchFeeDetails.dispatch_header,
            dispatch_body: customUrlData.dispatchFeeDetails.dispatch_body,
            dispatch_text: customUrlData.dispatchFeeDetails.dispatch_text,
            use_default_dispatch:false
          }:{
            ...state.additionalInfo.jobItemToBook,
            member_dispatch_fee:false,
            dispatch_fee: 0,
            dispatch_header: "",
            dispatch_body:  "",
            dispatch_text:  "",
            use_default_dispatch:false
          }
          updateState({
            ...state,
            cookie: newDataCookies,
            clientInfo: {
              ...state.clientInfo,
                isExistingCustomer: customUrlData?.customerType==="Existing Customer"?true:false,
                isNewCustomer: customUrlData?.customerType==="New Customer"?true:false,
            },
            additionalInfo:{
              ...state.additionalInfo,
              jobItemToBook:dispatchFeeDetails,
              },
            organizationDetails: orgData,
            customUrlData:customUrlData,
          });
          if(customUrlData?.customerType!=='Both'){
            setCurrentMainStep(0);
            setCurrentSubStep(0);
          }
      }
      }
      else{
        updateState({
          ...state,
          cookie: newDataCookies,
          organizationDetails: orgData,
        });
      }
        myRef.current.isDataArrived = true;
      }
    }
  };
  
  const messageEventListener=(event) =>{
    if (event?.data?.siteUrl && event?.data?.cookies) {
        handleAsyncEvent(event);
    } else {
        window.removeEventListener("message", messageEventListener);
    }
}

const localFunction = async () => {
  const params = new URLSearchParams(window.location.search);
  const paramsObject = {};
  for (const [key, value] of params.entries()) {
    paramsObject[key] = value;
  }

  const websiteURL = "https://texomateam.com";
  const webCookies = "direct+..+none+..++..++..++..++..+https%3A%2F%2Fwww.cardinalplumbingva.com%2F%23schedule-engine+..+109535-7ff2b995-afbb-68f0-5b4c-bf5554706d76+..++..+";
  const orgData=await fetchOrganization(websiteURL, webCookies);
  OpenFeature.setContext({
    id: orgData?.id,
  });
  if(paramsObject?.c){
    setIsCustomUrl(true)
    const customUrlData=await customUrlDetails(paramsObject?.c);
    const dispatchFeeStatus=customUrlData?.dispatchFee;
    const dispatchFeeDetails=dispatchFeeStatus?{
      ...state.additionalInfo.jobItemToBook,
      member_dispatch_fee:customUrlData.dispatchFeeDetails.member_dispatch_fee,
      dispatch_fee: customUrlData.dispatchFeeDetails.dispatch_fee,
      dispatch_header: customUrlData.dispatchFeeDetails.dispatch_header,
      dispatch_body: customUrlData.dispatchFeeDetails.dispatch_body,
      dispatch_text: customUrlData.dispatchFeeDetails.dispatch_text,
      use_default_dispatch:false
    }:{
      ...state.additionalInfo.jobItemToBook,
      member_dispatch_fee:false,
      dispatch_fee: 0,
      dispatch_header: "",
      dispatch_body:  "",
      dispatch_text:  "",
      use_default_dispatch:false
    }
    if(customUrlData){
      updateState({
        ...state,
        cookie: webCookies,
        clientInfo: {
          ...state.clientInfo,
            isExistingCustomer: customUrlData?.customerType==="Existing Customer"?true:false,
            isNewCustomer: customUrlData?.customerType==="New Customer"?true:false,
        },
        additionalInfo:{
					...state.additionalInfo,
          jobItemToBook:dispatchFeeDetails,
				  },
        organizationDetails: orgData,
        customUrlData:customUrlData,
      });
      if(customUrlData?.customerType!=='Both'){
        setCurrentMainStep(0);
        setCurrentSubStep(0);
      }
  }
  }
  else{
    updateState({
      ...state,
      cookie: webCookies,
      organizationDetails: orgData,
    });
  }
};
const customUrlDetails = async (customUrlCode) => {
  try{
    const response=await getOrganizationDetailsByCustomUrl(customUrlCode);
    return response?.data;
  }
  catch(err){
    console.log(err);
  }
 

}


window.addEventListener("message", messageEventListener);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    const { siteUrl: currentSiteUrl, cookies: currentCookies } = getQueryParams(
      window.location.search
    );

    OpenFeature.setProvider(new InMemoryProvider(flagConfig));

    if (currentSiteUrl && currentCookies && state.organizationDetails === null) {
      handleAsyncEvent();
    }

    const schedTag = new URLSearchParams(window.location.search).get("schedtag");
    if (
      window.location.origin.indexOf("localhost") !== -1 ||
      window.location.origin.indexOf("scheduler.autobot.ai") !== -1 ||
      window.location.origin.indexOf("dev.scheduler.autobot.ai") !== -1 ||
      schedTag === "true"
    ) {
      if (open === false) {
        setOpen(true);
      }
    }

    if (
      window.location.origin.indexOf("localhost") !== -1 &&
      state?.organizationDetails === null
    ) {
      void localFunction()
    }

    return () => {
      window.removeEventListener("message", messageEventListener);
    };
  }, []);


  const isServiceTypeScreen = currentMainStep === 1 && currentSubStep === 1;

  return (
    <>
    <OpenFeatureProvider flagConfig={flagConfig}>
      <Drawer
        className="drawer_main"
        placement="right"
        width={560}
        closeIcon={null}
        open={open}
      >
        {orgLoading && (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        )}

        <div className="heading_close_otr">
          <h3 className="modal_heading">CREATE APPOINTMENT</h3>
          <Button className="close_icon_otr" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"
                fill="currentColor"
              ></path>
            </svg>
          </Button>
        </div>
        {orgError ? (
          <div className="error-container">
            <Result
              status="error"
              title="Something Went Wrong!"
              subTitle="Please try again and if the problem persists, contact your service provider."
            ></Result>
          </div>
        ) : (
          <>
            <Stepper currentStep={currentMainStep} />
            {(state?.customUrlData?(currentMainStep > 3):(currentMainStep > 4)) &&
            currentSubStep > totalSteps?.review?.totalSteps ? (
                <AppointmentCreated onClose={onClose} />
            ) : (
              <>
                <div
                  className={`steps_content_main ${
                    isServiceTypeScreen ? "grey_bg" : null
                  }`}
                >
                  {iscustomUrl?paramFormComponents[currentMainStep]?.component:FormComponents[currentMainStep]?.component}
                </div>

                <NavigationalButton
                  currentMainStep={currentMainStep}
                  currentSubStep={currentSubStep}
                  totalSteps={totalSteps}
                  mainStepComponents={iscustomUrl?paramsmainStepComponents:mainStepComponents}
                  setCurrentMainStep={setCurrentMainStep}
                  setCurrentSubStep={setCurrentSubStep}
                />
              </>
            )}
          </>
        )}
      </Drawer>
      </OpenFeatureProvider>
    </>
  );
};

export default App;
