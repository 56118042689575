import React, { useEffect, useRef, useState } from "react";
import { Radio } from "antd";
import AppointmentDatePicker from "./AppointmentDatePicker";
import { getTimeSlots } from "../../apis/calender";
import "./AppointmentCalendar.css";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { LoadingSpinner } from "../General/Spinner/index.tsx";
import { isDatefrompast, isTimeRangeOverlap, trackUserInteraction } from "../../Utils/common-utils";
import moment from 'moment-timezone';

const AppointmentCalendar = () => {
	const { state, updateState } = useContext(AppContext);
	const [calendarData, setCalendarData] = useState(null);
	const [timeSlots, setTimeSlots] = useState([]);
	const [timeLoading, setTimeLoading] = useState(false);
	const [selectedTimeSlot, setSelectedTimeSlot] = useState([]);
	const timeSlotRef = useRef(null);

	const controller = useRef(new AbortController());

	useEffect(() => {
		const currentController = !controller?.current ? new AbortController() : controller?.current;
		trackUserInteraction({
			type:"page",
			page: 'AppointmentCalendar',
			buttonName: 'Select Time Slot',
			buttonValue: 'Select Time Slot',
			timestamp: new Date().toISOString(),
			organization_name: state?.organizationDetails?.organization_name,
			sessionId: state?.clientInfo?.followupleadid,
			step: 6
		});
		
		fetchTimeSlots(currentController);
		return () => {
			currentController.abort();
		};
	}, []);

	
	useEffect(() => {
		if (selectedTimeSlot) {
			updateState({ ...state, isSubStepValid: true });
		}
	}, [selectedTimeSlot]);


	const convertToAMPMWithoutSeconds = (time) => {
		const [hours, minutes] = time.split(":");
		let period = "AM";
		if (parseInt(hours) >= 12) period = "PM";
		const hours12 = parseInt(hours) % 12 || 12;
		return `${hours12}:${minutes} ${period}`;
	};

	const formatTimeRange = (timeArray) => {
		return timeArray?.map((timeObj) => {
			const startTime = convertToAMPMWithoutSeconds(timeObj.start);
			const endTime = convertToAMPMWithoutSeconds(timeObj?.end);
			return {
				start: startTime,
				end: endTime,
				startUtc: timeObj?.startUtc,
				endUtc: timeObj?.endUtc,
			};
		});
	};

	const checkIfinputDateExist = (option, timeZone) => {
		if (!option?.startUtc) return false;
		const blockDate = moment.tz(option.startUtc, timeZone).startOf('day').format("YYYY-MM-DD");
		return state?.organizationDetails?.holidays?.find((dateInfo) => {
			const formattedHolidayDate = moment(dateInfo.date, "YYYY-MM-D").format("YYYY-MM-DD");
			return formattedHolidayDate === blockDate;
		});
	};

	const handleTimeSlot = (slots) => {
		const formattedTimeSlots = formatTimeRange(slots);
		let updatedSlots = formattedTimeSlots;

		if (!formattedTimeSlots) {
			setTimeSlots(formattedTimeSlots);
			return;
		}
		const timeZone = state.organizationDetails?.organization_timeZone || "UTC";
		const currentDate = moment.tz(timeZone).startOf('day');
		const slotEndDate = moment.tz(formattedTimeSlots[0].startUtc, timeZone).startOf('day');
		const isSameDate = currentDate.isSame(slotEndDate, 'day');
		if (isSameDate) {
			updatedSlots = formattedTimeSlots?.filter((slot) => isDatefrompast(slot, timeZone,state?.organizationDetails?.id)) || [];
		}

		const blockedSlots = updatedSlots?.filter((option) => {
			const blockbyadmin = checkIfinputDateExist(option, timeZone);
			if (!blockbyadmin) return false;
			if (blockbyadmin.BlockAllday) return true;
			const formattedDateRange = `${option?.start} - ${option?.end}`;
			const formattedHolidayDate = moment(blockbyadmin?.date, "YYYY-MM-D").format("YYYY-MM-DD");
			return isTimeRangeOverlap(formattedDateRange, blockbyadmin?.TimeRange, timeZone, formattedHolidayDate);
		});
		const notBlockedSlots = updatedSlots?.filter((option) => !blockedSlots.includes(option));
		setTimeSlots(blockedSlots.length === updatedSlots?.length ? null : notBlockedSlots);
	};

	const fetchTimeSlots = async ({signal}) => {
		try {
			let newState = {
				...state,
				isSubStepValid: false,
				miscellaneous: { ...state.miscellaneous, loading: true, appointmentReviewPage: false },
			}
			updateState({...newState});
			setTimeLoading(true);

			const payload = {
				organizationId: state?.organizationDetails?.id,
				businessUnit: state?.customUrlData?.buId?.id || state?.additionalInfo?.jobItemToBook?.st_business_unit_id || "",
			};
			
			const res = await getTimeSlots(payload, signal );
			setCalendarData(res);
		} catch (err) {
			if (signal.aborted) {
				updateState({
					...state,
					isSubStepValid: true,
					miscellaneous: { ...state.miscellaneous, loading: false },
				});
			} else {
				console.error(err);
				updateState({
					...state,
					isSubStepValid: false,
					miscellaneous: { ...state.miscellaneous, loading: false },
				});
			}
		} finally {
			setTimeLoading(false);
		}
	};

	return (
		<div className="appointment-date-picker">
			{timeLoading && (
				<div className="spinner-container">
					<LoadingSpinner />
				</div>
			)}

			<div className="AppointmentCalendar_otr">
				<AppointmentDatePicker
					calendarData={calendarData}
					onSelectedDate={handleTimeSlot}
					setSelectedTimeSlot={setSelectedTimeSlot}
					timeSlotRef={timeSlotRef}
				/>
				<div id="timeSlots" ref={timeSlotRef} className="date_radio_otr">
					{timeSlots ? (
						<>
							<p className="date_text">Please select one of the available time slots</p>
							<div className="date_check_group_wrapper">
								<Radio.Group
									className="radio_check_group"
									onChange={(e) => {
										setSelectedTimeSlot(e.target.value);
										const day=moment.tz(e?.target?.value?.startUtc, state?.organizationDetails?.timeZone).startOf('day').format("dddd");
										updateState({
											...state,
											appointment: {
												...state.appointment,
												arrivalWindowStart: e?.target.value?.startUtc,
												arrivalWindowEnd: e?.target.value?.endUtc,
												displayStartTime: e?.target?.value?.start,
												displayEndTime: e?.target?.value?.end,
												day,
											},
										});
									}}
									value={selectedTimeSlot}
								>
									{timeSlots?.map((option) => (
										<div className="time_slot_radio_btn_wrapper" key={`${option?.start} - ${option?.end}`}>
											<Radio value={option}>{`${option?.start} - ${option?.end}`}</Radio>
										</div>
									))}
								</Radio.Group>
							</div>
						</>
					) : (
						<span className="noslots_response">There are no openings remaining for this date. Please select a different date, or call us if this is an emergency.</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default AppointmentCalendar;
